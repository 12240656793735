<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-settings" /> Game Configurations
          <div class="card-header-actions">
            <small class="text-muted">Total: {{ configs.length }}</small>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="configs"
          :fields="configFields"
          :hover="hover"
          column-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          pagination >

          <template #configValue="{ item }">
              <td class="py-2">
                  <CInput placeholder="Value" v-model="item.value" />
              </td>
          </template>

          <template #configRevision="{ item }">
              <td class="py-2">
                  <CInput placeholder="Revision" v-model="item.revision" />
              </td>
          </template>

          <template #configisClient="{ item }">
              <td class="py-2">
                <div class="custom-control custom-checkbox  center" >
                    <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="'isClient' + item.id"
                        v-model="item.isForClient" />
                    <label class="custom-control-label" :for="'isClient' + item.id"></label>
                </div>
              </td>
          </template>

          <template #update="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="updateConfig(item)" >
                Update
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "GameConfigs",
  components: {
    Loading,
  },
  data() {
    return {
      configs: [],
      configFields: [
        { key: "id", label: "ID", filter: false, sorter: false },
        { key: "world_id", label: "World ID", filter: false, sorter: false },
        { key: "key", label: "Key" },
        { key: "configValue", label: "Value" },
        { key: "configRevision", label: "Revision", filter: false, sorter: false },
        { key: "configisClient", label: "Client", filter: false, sorter: false },
        { key: "update", label: "", filter: false, sorter: false },
      ],
      hover: true,
      isLoading: false,
    };
  },
  methods: {
    getConfigs() {
      this.isLoading = true;
      this.$http
        .get(this.$config.gmapi + "/gameconfig", {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.configs = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    },
    updateConfig(item) {
        this.isLoading = true;
        this.$http.put(this.$config.gmapi + '/gameconfig', item, {headers: this.$config.getHeaders() }).then(response => {
            return response.json();
        }).then(jsonData => {
            this.getConfigs();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
            this.isLoading = false;
        }); 
    }
  },
  created() {
    this.getConfigs();
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
